import React from "react";

function FlightCard({ flight, setSelectedFlights, selectedFlights }) {
  function handleClick() {
    setSelectedFlights((prevState) => {
      if (
        prevState.departure?.ProductId === flight.ProductId ||
        prevState.arrival?.ProductId === flight.ProductId
      ) {
        return {
          departure:
            prevState.departure?.ProductId === flight.ProductId
              ? null
              : prevState.departure,
          arrival:
            prevState.arrival?.ProductId === flight.ProductId
              ? null
              : prevState.arrival,
        };
      }

      return {
        departure: flight.Segments[0].Way === 1 ? flight : prevState.departure,
        arrival: flight.Segments[0].Way === 2 ? flight : prevState.arrival,
      };
    });
  }

  return (
    <div>
      {/* FH Title */}
      {/* FH Title */}
      <div className="fh-title">
        <span className="title">
          {flight.Segments[0].Way === 1 ? "GİDİŞ" : "DÖNÜŞ"}
          {flight.Segments[0].Way === 1 ? (
            <i className="fa fa-fw fa-plane" />
          ) : (
            <i
              className="fa fa-fw fa-plane"
              style={{ transform: "rotate(90deg)" }}
            />
          )}
          <br />
          {new Date(flight.Segments[0].DepartureDate).toLocaleDateString(
            "tr-TR",
            {
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "long",
            }
          )}
        </span>
      </div>
      {/* FH Title */}
      {/* FH Title */}

      {/* Wide Flight Box */}
      <div className="wide-flight-box ">
        {/* Wfb Left */}
        <div className="wfb-left">
          {/* Airline */}
          <div className="airline">
            <div className="logo">
              <img
                src={`img/Airline/${flight.Segments[0].OperatingAirline}.png`}
              />
            </div>
            <div className="name">
              {flight.Segments[0].OperatingAirlineName}
            </div>
          </div>
          <div className="wl-sc">
            <span className="seat">
              Son {flight.SegmentAvailabilities[0].AvailableSeats} Koltuk
            </span>
            <div className="class">{flight.Segments[0].BookingClass} Class</div>
          </div>
          <div className="wld">
            <span className="time">
              {/* // 21:00:00 convert to 21:00 */}
              {flight.Segments[0].DepartureTime.substr(0, 5)} -{" "}
              {flight.Segments[0].ArrivalTime.substr(0, 5)}
            </span>
            {flight?.Segments[1]?.ArrivalDate.substr(8, 2) -
              flight?.Segments[0]?.DepartureDate.substr(8, 2) >
            0 ? (
              <span className="mainColor"> (+1)</span>
            ) : flight?.Segments[0]?.ArrivalDate.substr(8, 2) -
                flight?.Segments[0]?.DepartureDate.substr(8, 2) >
              0 ? (
              <span className="mainColor"> (+1)</span>
            ) : (
              ""
            )}
            <br />
            {flight.Segments[0].DepartureAirportName} -{" "}
            {flight.Segments[flight.Segments.length - 1].ArrivalAirportName}
          </div>
        </div>
        {/* Wfb Left */}
        {/* Wfb Right */}
        <div className="wfb-right">
          <div className="price">
            {flight.TotalFare} {flight.Currency}{" "}
          </div>
          <div className="prom">
            <div>
              {flight.Segments[0].Cabin === "Economy" && (
                <span className="">Ekonomi</span>
              )}
              {flight.Segments[0].Cabin === "SYSTEM" && (
                <span className="">Sistem</span>
              )}
              {flight.Segments[0].Cabin === "CONTRACTED" && (
                <span className="">Promosyonlu</span>
              )}
            </div>

            {flight.Segments[0].Cabin === "CONTRACTED" && (
              <span className="mainColor">
                <i className="fa fa-fw fa-star" /> Tatil Sitesi Özel
              </span>
            )}
          </div>
          <div>
            <a className="fl-book" onClick={handleClick}>
              {selectedFlights.departure?.ProductId === flight.ProductId ||
              selectedFlights.arrival?.ProductId === flight.ProductId ? (
                <span className="selected-button-custom">
                  <i className="fa fa-fw fa-check-circle" /> Seçildi
                </span>
              ) : (
                <span>
                  Seç <i className="fa fa-fw fa-arrow-circle-right" />
                </span>
              )}
            </a>
          </div>
        </div>
        {/* Wfb Right */}
        {/* Wfb Transfer */}

        <div>
          {flight.Segments.length > 1 ? (
            <div className="transfer">Aktarmalı Uçuş</div>
          ) : (
            <div className="transfer">Direkt Uçuş</div>
          )}
        </div>

        {/* Wfb More */}
        <div className="more">Uçuş Detayları</div>
        {/* Wfb Details */}
        <div className="wfb-details">
          <div className="mid-title mt0">
            <i className="fa fa-fw fa-plane" />{" "}
            {(() => {
              const totalMinutes = flight.Segments[0].FlightTime;
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
              if (hours > 0) {
                return `${hours} saat ${minutes} dk`;
              } else {
                return `${minutes} dk`;
              }
            })()}
          </div>
          {/* segments is map*/}
          {flight.Segments.map((segment) => (
            <>
              <table className="full-table">
                <tbody>
                  <tr>
                    <td>
                      <strong className="thirdColor">
                        {segment.OperatingAirlineName}
                      </strong>
                      <br />
                      Uçuş - {flight.Segments[0].FlightNumber}
                      <br />
                    </td>
                    <td>
                      <i className="fa fa-fw fa-plane" />{" "}
                      {segment.DepartureAirportName}
                      <br />
                      <i
                        className="fa fa-fw fa-plane"
                        style={{ transform: "rotate(90deg)" }}
                      />{" "}
                      {segment.ArrivalAirportName}
                    </td>
                    <td className="tr">
                      <span className="darkColor"></span>
                      {new Date(segment.DepartureDate).toLocaleDateString(
                        "tr-TR",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}

                      <br />

                      <span className="darkColor"> </span>
                      {new Date(segment.ArrivalDate).toLocaleDateString(
                        "tr-TR",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ))}

          <div className="wfb-bottom">
            <i className="fa fa-fw fa-suitcase" /> Toplam Bagaj Hakkı (1 Yolcu)
            : {flight.Baggage.Quantity} {flight.Baggage.Type}
          </div>
        </div>
        {/* Wfb Details */}
      </div>
      {/* Wide Flight Box */}
    </div>
  );
}

export default FlightCard;
