import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import FlightBox from "./Components/FlightBox";
import { Toaster, toast } from "react-hot-toast";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./App.css";
import useDebounce from "./Hooks/useDebounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const directionRef = useRef(null);
  const departurePlaceRef = useRef(null);
  const arrivalPlaceRef = useRef(null);
  const departureDateRef = useRef(null);
  const returnDateRef = useRef(null);
  const passengersRef = useRef(null);
  const child1Ref = useRef(null);
  const child2Ref = useRef(null);
  const child3Ref = useRef(null);
  const childCountRef = useRef(null);

  const days = ["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"];
  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  const [token, setToken] = useState(null);
  const [isFlightsLoading, setIsFlightsLoading] = useState(null);
  const [flights, setFlights] = useState(null);
  const [departureSearchQuery, setDepartureSearchQuery] = useState("");
  const [arrivalSearchQuery, setArrivalSearchQuery] = useState("");
  const [SearchResults, setSearchResults] = useState([]);
  const [isArrivalDropDownOpen, setIsArrivalDropDownOpen] = useState(false);
  const [isDepartureDropDownOpen, setIsDepartureDropDownOpen] = useState(false);
  const [selectFilter, setSelectFilter] = useState([]);
  const [selectedFlights, setSelectedFlights] = useState({
    departure: null,
    arrival: null,
  });
  const [airlineFilter, setAirlineFilter] = useState([]);
  const [selectTransferFilter, setSelectTransferFilter] = useState([]);
  const [transferFilter, setTransferFilter] = useState(null);
  const [promFilter, setPromFilter] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 999999999]);
  const priceRangeFilter = useDebounce(priceRange, 600);
  const [priceMinMax, setPriceMinMax] = useState([0, 999999999]);
  const [PassengerValue, setPassengerValue] = useState(1);
  const [selectedDate, setSelectedDate] = useState(
    departureDateRef?.current?.value || null
  );
  const [returnDateSelected, setReturnDateSelected] = useState(
    returnDateRef?.current?.value || null
  );
  const [departurePlace, setDeparturePlace] = useState(null);
  const [arrivalPlace, setArrivalPlace] = useState(null);
  const [beforeCtrl, setBeforeCtrl] = useState(false);

  const [isOneWay, setIsOneWay] = useState(false);

  const loginHandler = () => {
    axios
      .post("https://flightservice.tatilsitesi.com/Home/Login", {
        UserName: "B2CUSERDF",
        Password: "1234",
        MarketCode: "TATILSITESI",
      })
      .then((res) => {
        setToken(res?.data?.Token);
      });
  };

  function generateGUID() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  }

  const placeholderText = () => {
    if (departurePlace && arrivalPlace) {
      return (
        <div className="flights-title">
          <span className="title">
            <i className="fa fa-fw fa-plane" /> {departurePlace} -{" "}
            {arrivalPlace}
          </span>
        </div>
      );
    }
  };

  useEffect(() => {
    if (departureSearchQuery?.length >= 3 || arrivalSearchQuery?.length >= 3) {
      // every 500ms we will send a request to the server
      const timer = setTimeout(() => {
        axios
          .post(
            "https://flightservice.tatilsitesi.com/Tournate/GetAirportWithLang",
            {
              Key:
                departureSearchQuery?.length >= 3
                  ? departureSearchQuery
                  : arrivalSearchQuery,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            res?.data?.Data && setSearchResults(res.data.Data);
            res?.data?.Data?.length > 0 && departureSearchQuery?.length >= 3
              ? setIsDepartureDropDownOpen(true)
              : setIsArrivalDropDownOpen(true);
          })
          .catch((err) => {
            if (err?.response?.status === 401) {
              loginHandler();
            }
          });
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [departureSearchQuery, arrivalSearchQuery]);

  const infHandler = () => {
    // inf can only be 0 or 1 age
    let inf = 0;
    const childCount = Number(childCountRef?.current?.value);
    const chd1 = Number(child1Ref?.current?.value);
    const chd2 = Number(child2Ref?.current?.value);
    const chd3 = Number(child3Ref?.current?.value);
    if (childCount > 0 && chd1 >= 0 && chd1 <= 1) {
      inf++;
    }
    if (childCount > 1 && chd2 >= 0 && chd2 <= 1) {
      inf++;
    }
    if (childCount > 2 && chd3 >= 0 && chd3 <= 1) {
      inf++;
    }
    return inf;
  };

  const chdHandler = () => {
    let chd = 0;
    const chd1 = Number(child1Ref?.current?.value);
    const chd2 = Number(child2Ref?.current?.value);
    const chd3 = Number(child3Ref?.current?.value);
    if (chd1 >= 2 && chd1 <= 12) {
      chd++;
    }
    if (chd2 >= 2 && chd2 <= 12) {
      chd++;
    }
    if (chd3 >= 2 && chd3 <= 12) {
      chd++;
    }
    return chd;
  };

  const passengersHandler = () => {
    const AdtPassenger = Number(passengersRef?.current?.value);
    const ChdPassenger = chdHandler();
    const InfPassenger = infHandler();
    const totalPassenger = `${AdtPassenger} + ${ChdPassenger + InfPassenger}`;
    setPassengerValue(totalPassenger);
  };

  const flightSearchHandler = () => {
    // if directionref contains class active
    const twoWay = directionRef.current.classList.contains("active");
    setIsOneWay(!twoWay);
    if (!selectedDate) {
      toast.error("Lütfen kalkış tarihi seçiniz.");
      departureDateRef.current.setOpen();
      return;
    }

    const utcDepDate = new Date(
      selectedDate?.getTime() - selectedDate?.getTimezoneOffset() * 60 * 1000
    );

    // console.log(utcDepDate);

    const departureDate = utcDepDate?.toISOString().slice(0, 10);

    // console.log(departureDate);

    const utcRetDate = new Date(
      returnDateSelected?.getTime() -
        returnDateSelected?.getTimezoneOffset() * 60 * 1000
    );

    const returnDate = utcRetDate?.toISOString().slice(0, 10);

    if (!returnDate && twoWay) {
      toast.error("Lütfen dönüş tarihi seçiniz.");
      returnDateRef.current.setOpen();
      return;
    }

    const passengers = Number(passengersRef.current.value);

    const infants = infHandler();

    setSelectedFlights([]);

    setDeparturePlace(departureSearchQuery?.CityName);
    setArrivalPlace(arrivalSearchQuery?.CityName);

    if (passengers <= 1 && infants === 2) {
      toast.error("Bebek sayısı yetişkin saysısından fazla olamaz.");
      return;
    }

    if (!departureSearchQuery?.AirportName) {
      toast.error("Lütfen kalkış yeri seçiniz.");
      departurePlaceRef.current.focus();
    } else if (!arrivalSearchQuery?.AirportName) {
      toast.error("Lütfen varış yeri seçiniz.");
      arrivalPlaceRef.current.focus();
    } else if (twoWay && selectedDate > returnDateSelected) {
      toast.error("Dönüş tarihi gidiş tarihinden önce olamaz.");
    } else {
      setFlights(null);
      setIsFlightsLoading(true);

      const body = {
        //random guid generate
        PartialGuid: generateGUID(),
        IntendedSegments: [
          {
            Index: 1,
            Origin: {
              AirportCode: departureSearchQuery?.AirportCode,
              IsAirport: true,
            },
            Destination: {
              AirportCode: arrivalSearchQuery?.AirportCode,
              IsAirport: true,
            },
            DepartureDate: new Date(departureDate),
          },
          // if two way add one more else just one element
          twoWay
            ? {
                Index: 2,
                Origin: {
                  AirportCode: arrivalSearchQuery?.AirportCode,
                  IsCity: true,
                },
                Destination: {
                  AirportCode: departureSearchQuery?.AirportCode,
                  IsCity: false,
                },
                DepartureDate: new Date(returnDate),
              }
            : null,
        ].filter((el) => el !== null),

        Paxes: [
          {
            PaxType: "ADT",
            Count: Number(passengersRef.current.value),
          },
          {
            PaxType: "CHD",
            Count: chdHandler(),
          },
          {
            PaxType: "INF",
            Count: infHandler(),
          },
        ],
        Options: {
          IfRefundablesPrefered: false,
          IfFlexibleDates: true,
          IfBaggage: false,
          IsDomestic: true,
          IfThyMilBilet: true,
        },
        FlightType: twoWay ? "RT" : "OW",
      };

      partialSearchHandler(body);
    }
  };

  const partialSearchHandler = (body) => {
    axios
      .post(
        "https://flightservice.tatilsitesi.com/Air/AirSearchPartial",
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setFlights(res?.data?.Data);
        if (res?.data?.Status === "InProcess") {
          setTimeout(() => {
            partialSearchHandler(body);
          }, 500);
        } else {
          setPriceMinMax(() => {
            const prices = res?.data?.Data?.FlightOptions?.map(
              (f) => f?.TotalFare
            );
            const max = Math.max(...prices);
            const min = Math.min(...prices);
            setPriceRange([min, max]);
            return [min.toFixed(0), max.toFixed(0)];
          });
          setIsFlightsLoading(false);
        }
      });
  };

  useEffect(() => {
    loginHandler();
    // select range-slider__range div and default style left set
    const rangeSlider__range = document.querySelector(".range-slider__range");
    rangeSlider__range.style.left =
      (priceRange[0] / (priceMinMax[1] - priceMinMax[0])) * 100 + 1 + "%";
    rangeSlider__range.style.width =
      ((priceRange[1] - priceRange[0]) / (priceMinMax[1] - priceMinMax[0])) *
        100 +
      "%";
  }, []);

  const airlineFilterHandler = (e, airline) => {
    if (e.target.checked) {
      setAirlineFilter((prev) => [...prev, airline]);
      setSelectFilter((prev) => [...prev, airline]);
    } else {
      setAirlineFilter((prev) => prev.filter((a) => a !== airline));
      setSelectFilter((prev) => prev.filter((a) => a !== airline));
    }
    return;
  };

  const removeAirlineFilter = (airline) => {
    setSelectFilter((prev) => prev.filter((a) => a !== airline));
    setAirlineFilter((prev) => prev.filter((a) => a !== airline));

    const checkbox = document.querySelector(
      `input[type="checkbox"][value="${airline}"]`
    );
    checkbox.checked = false;
  };

  const tranferFilterHandler = (e, transfer) => {
    if (e.target.checked) {
      setTransferFilter(transfer);
      setSelectTransferFilter((prev) => [...prev, transfer]);
    } else {
      setTransferFilter(null);
      setSelectTransferFilter((prev) => prev.filter((a) => a !== transfer));
    }

    // İlgili filtre kutusunun işaretini kaldırın
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox.value === transfer.toString()) {
        checkbox.checked = false;
      }
    });
  };

  const removeTransferFilter = (transfer) => {
    setTransferFilter(null);
    setSelectTransferFilter((prev) => prev.filter((a) => a !== transfer));

    // İlgili filtre kutusunun işaretini kaldırın
    const checkbox = document.querySelector(
      `input[type="checkbox"][id="bb${transfer}"]`
    );
    checkbox.checked = false;
  };

  const promFilterHandler = (e) => {
    setPromFilter(e.target.checked);
  };
  const removePromFilter = (promFilter) => {
    setPromFilter(null);

    // İlgili filtre kutusunun işaretini kaldırın
    const checkbox = document.querySelector(`input[type="checkbox"][id="aa1"]`);
    if (checkbox) {
      checkbox.checked = false;
    }
  };
  const handleDepartureDateChange = (date) => {
    setSelectedDate(new Date(date));
    setReturnDateSelected(date);
    const twoWay = directionRef.current.classList.contains("active");
    if (twoWay) {
      returnDateRef.current.setOpen(true);
      returnDateRef.current.input.focus();
    }
  };

  const handleReturnDateChange = (date) => {
    setReturnDateSelected(date);
  };

  return (
    <div>
      {/* Container */}
      <div className="container">
        <div className="full-flights">
          {/* CL 250 */}
          <div className="cLeft25">
            <div className="mobileFilterTrigger">
              <i className="fa fa-fw fa-list-ul" />
            </div>
            {/* Filters */}
            <div className="filters">
              <div className="f-title">
                <strong>Sonuçları</strong> Filtreleyin
              </div>
              {/* Filter Acc */}
              <div className="filter-acc">
                <div className="fa-title tnSimpleAccTrigger">Fiyat Aralığı</div>
                <div
                  className="fa-ct tnSimpleAccContent"
                  style={{
                    display: "none",
                  }}
                >
                  {/* Filter Slider */}

                  <div className="slider-filter">
                    <div className="filter-vals">
                      <div className="lVal">{priceMinMax[0]} TL</div>
                      <div className="rVal">{priceMinMax[1]} TL</div>
                    </div>
                    <RangeSlider
                      id={"priceRange"}
                      min={priceMinMax[0]}
                      max={priceMinMax[1]}
                      value={priceRange}
                      onInput={(value) => {
                        setPriceRange(value);
                      }}
                      className="slider-price"
                    />
                    <div
                      className="price-box"
                      style={{
                        position: "absolute",
                        zIndex: 1000,
                        left: `calc(${
                          ((priceRange[0] - priceMinMax[0]) /
                            (priceMinMax[1] - priceMinMax[0])) *
                          100
                        }%)`,
                        //-2rem delete
                      }}
                    >
                      {priceRange[0]} TL
                    </div>
                    <div
                      className="price-box"
                      style={{
                        position: "absolute",
                        bottom: -10,
                        zIndex: 1000,

                        left: `calc(${
                          ((priceRange[1] - priceMinMax[0]) /
                            (priceMinMax[1] - priceMinMax[0])) *
                          100
                        }% - 3rem)`,
                        //-2rem delete
                      }}
                    >
                      {priceRange[1]} TL
                    </div>
                  </div>
                  {/* Filter Slider */}
                </div>
              </div>
              {/* Filter Acc */}
              {/* Filter Acc */}
              <div className="filter-acc">
                <div className="fa-title tnSimpleAccTrigger">
                  Havayolu Firmaları
                  <br />
                  <span>Birden Fazla Seçim Yapabilirsiniz</span>
                </div>
                <div className="fa-ct tnSimpleAccContent">
                  <ul className="check-list">
                    <li>
                      <div className="customCheck">
                        <input
                          onClick={(e) => airlineFilterHandler(e, "AJ")}
                          type="checkbox"
                          id="cc1"
                          value="AJ"
                        />
                        <label htmlFor="cc1">AnadoluJet</label>
                        <img
                          className="filter-flight-logo"
                          src="/img/Airline/AJ.png"
                          alt=""
                        />
                      </div>
                    </li>
                    <li>
                      <div className="customCheck">
                        <input
                          onClick={(e) => airlineFilterHandler(e, "PC")}
                          type="checkbox"
                          id="cc3"
                          value={"PC"}
                        />
                        <label htmlFor="cc3">Pegasus Hava Yolları</label>
                        <img
                          className="filter-flight-logo"
                          src="/img/Airline/PC.png"
                          alt=""
                        />
                      </div>
                    </li>
                    <li>
                      <div className="customCheck">
                        <input
                          onClick={(e) => airlineFilterHandler(e, "TK")}
                          type="checkbox"
                          id="cc4"
                          value="TK"
                        />
                        <label htmlFor="cc4">Türk Hava Yolları</label>
                        <img
                          className="filter-flight-logo"
                          src="/img/Airline/TK.png"
                          alt=""
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Filter Acc */}
              {/* Filter Acc */}
              <div className="filter-acc">
                <div className="fa-title tnSimpleAccTrigger">
                  Aktarma Seçeneği
                  <br />
                  <span>Birden Fazla Seçim Yapabilirsiniz</span>
                </div>
                <div className="fa-ct tnSimpleAccContent">
                  <ul className="check-list">
                    <li>
                      <div className="customCheck">
                        <input
                          onClick={(e) => {
                            tranferFilterHandler(e, 2);
                          }}
                          type="checkbox"
                          id="bb2"
                        />
                        <label htmlFor="bb2">Aktarmalı</label>
                      </div>
                    </li>
                    <li>
                      <div className="customCheck">
                        <input
                          onClick={(e) => {
                            tranferFilterHandler(e, 1);
                          }}
                          type="checkbox"
                          id="bb1"
                        />
                        <label htmlFor="bb1">Aktarmasız</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Filter Acc */}
              <div className="filter-acc">
                <div className="fa-title tnSimpleAccTrigger">
                  Promosyon Seçenği
                  <br />
                </div>
                <div className="fa-ct tnSimpleAccContent">
                  <ul className="check-list">
                    <li>
                      <div className="customCheck">
                        <input
                          onClick={(e) => {
                            promFilterHandler(e, "CONTRACTED");
                          }}
                          type="checkbox"
                          id="aa1"
                        />
                        <label htmlFor="aa1">Promosyonlu</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Filters */}
          </div>
          {/* CL 250 */}
          {/* Flights Title */}
          <div
            className="search tnTabber"
            style={{
              width: "90%",
              height: "100",
              marginTop: "3",
              backgroundColor: "none",
              border: "none",
            }}
          >
            {/* Flight Search Tab */}
            <div className="search-ct tnContent">
              {/* search Main */}
              <div className="searchMain flightSearch">
                <div className="ret-one-selector">
                  <label ref={directionRef} className="returnSelector active">
                    Gidiş Dönüş
                  </label>
                  <label className="oneWaySelector">Tek Yön</label>
                </div>
                <div className="searchMain_inputList">
                  <div className="depature_list">
                    <input
                      type="text"
                      ref={departurePlaceRef}
                      className="searchInput siDeparture fullwidth"
                      placeholder="Kalkış Şehri ya da Havalimanı"
                      value={
                        departureSearchQuery?.CityId
                          ? departureSearchQuery?.AirportName
                          : departureSearchQuery
                      }
                      onChange={(e) => setDepartureSearchQuery(e.target.value)}
                      onClick={() => {
                        setIsDepartureDropDownOpen(true);
                      }}
                    />
                    {isDepartureDropDownOpen &&
                      departureSearchQuery.length > 2 && (
                        <ul className={"dropdown-menu"}>
                          {SearchResults.map(
                            (result) =>
                              result.InMarket === 17 &&
                              result.LanguageCode === "TR" && (
                                <li
                                  key={result.AirportCode}
                                  className="dropdown-menu-list"
                                  onClick={() => {
                                    setIsDepartureDropDownOpen(false);
                                    setDepartureSearchQuery(result);
                                    setSearchResults([]);
                                  }}
                                >
                                  {result.AirportName.split("").map(
                                    (char, index) => {
                                      const searchQuery = departureSearchQuery
                                        .toLowerCase()
                                        .split(" ");
                                      const charLower = char.toLowerCase();
                                      const charIndexInQuery =
                                        searchQuery.findIndex((sq) =>
                                          sq.includes(charLower)
                                        );
                                      const isBold =
                                        charIndexInQuery !== -1 &&
                                        searchQuery[charIndexInQuery].substr(
                                          index - charIndexInQuery,
                                          char.length
                                        ) === charLower;

                                      return (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: isBold
                                              ? "bold"
                                              : "inherit",
                                          }}
                                        >
                                          {char}
                                        </span>
                                      );
                                    }
                                  )}
                                </li>
                              )
                          )}
                        </ul>
                      )}
                  </div>
                  <div className="depature_list">
                    <input
                      ref={arrivalPlaceRef}
                      type="text"
                      disabled={!departureSearchQuery?.CityId}
                      className="searchInput siArrival fullwidth"
                      placeholder="İniş Şehri ya da Havalimanı"
                      value={
                        arrivalSearchQuery?.CityId
                          ? arrivalSearchQuery?.AirportName
                          : arrivalSearchQuery
                      }
                      onChange={(e) => setArrivalSearchQuery(e.target.value)}
                      onClick={() => {
                        setIsArrivalDropDownOpen(true);
                      }}
                    />
                    {isArrivalDropDownOpen && arrivalSearchQuery.length > 2 && (
                      <ul className={"dropdown-menu"}>
                        {SearchResults.map(
                          (result) =>
                            result.InMarket === 17 &&
                            result.LanguageCode === "TR" && (
                              <li
                                key={result.AirportCode}
                                className="dropdown-menu-list"
                                onClick={() => {
                                  setIsArrivalDropDownOpen(false);
                                  setArrivalSearchQuery(result);
                                  setSearchResults([]);
                                }}
                              >
                                {result.AirportName.split("").map(
                                  (char, index) => {
                                    const searchText = arrivalSearchQuery
                                      .toLowerCase()
                                      .substr(0, 3);
                                    const charLower = char.toLowerCase();
                                    const isBold =
                                      searchText.includes(charLower) &&
                                      searchText.indexOf(charLower) === index;

                                    return (
                                      <span
                                        key={index}
                                        style={{
                                          fontWeight: isBold
                                            ? "bold"
                                            : "inherit",
                                        }}
                                      >
                                        {char}
                                      </span>
                                    );
                                  }
                                )}
                              </li>
                            )
                        )}
                      </ul>
                    )}
                  </div>

                  <div
                    style={{
                      width: "auto",
                    }}
                  >
                    <DatePicker
                      locale={locale}
                      ref={departureDateRef}
                      selected={selectedDate}
                      dateFormat={"dd/MM/yyyy"}
                      className="searchInput siDater depDate"
                      placeholderText="Gidiş Tarihi"
                      id="depId"
                      minDate={new Date()}
                      onChange={handleDepartureDateChange}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>

                  <div>
                    <DatePicker
                      locale={locale}
                      ref={returnDateRef}
                      selected={returnDateSelected}
                      dateFormat={"dd/MM/yyyy"}
                      className="searchInput siDater retDate"
                      placeholderText="Dönüş Tarihi"
                      id="retId"
                      onChange={handleReturnDateChange}
                      minDate={
                        selectedDate ? new Date(selectedDate) : new Date()
                      }
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>

                  {/* Passengers */}
                  <div className="passengers">
                    <div onClick={passengersHandler} class="passTrigger">
                      Yolcu Sayısı ({PassengerValue})
                    </div>
                    <div className="pass-drop unselectable">
                      <table className="full-table vm">
                        <tbody>
                          <tr>
                            <td>Yetişkin</td>
                            <td colSpan={3}>
                              <select
                                ref={passengersRef}
                                className="full-select"
                              >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Çocuk</td>
                            <td colSpan={3}>
                              <select
                                ref={childCountRef}
                                className="full-select childSelect"
                              >
                                <option>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="children">
                        {/* Child */}
                        <div className="child">
                          <div className="c-left">
                            <span>1.Çocuk Yaşı</span>
                            <br />0 - 12 Yaşları Arası
                          </div>
                          <div className="c-right ageSelector">
                            <div className="cr-minus">-</div>
                            <div className="cr-center">
                              <input
                                ref={child1Ref}
                                type="text"
                                defaultValue={0}
                                data-minval={0}
                                data-maxval={12}
                              />
                            </div>
                            <div className="cr-plus">+</div>
                          </div>
                        </div>
                        {/* Child */}
                        {/* Child */}
                        <div className="child">
                          <div className="c-left">
                            <span>2.Çocuk Yaşı</span>
                            <br />0 - 12 Yaşları Arası
                          </div>
                          <div className="c-right ageSelector">
                            <div className="cr-minus">-</div>
                            <div className="cr-center">
                              <input
                                ref={child2Ref}
                                type="text"
                                defaultValue={0}
                                data-minval={0}
                                data-maxval={12}
                              />
                            </div>
                            <div className="cr-plus">+</div>
                          </div>
                        </div>
                        {/* Child */}
                        {/* Child */}
                        <div className="child">
                          <div className="c-left">
                            <span>3.Çocuk Yaşı</span>
                            <br />0 - 12 Yaşları Arası
                          </div>
                          <div className="c-right ageSelector">
                            <div className="cr-minus">-</div>
                            <div className="cr-center">
                              <input
                                ref={child3Ref}
                                type="text"
                                defaultValue={0}
                                data-minval={0}
                                data-maxval={12}
                              />
                            </div>
                            <div className="cr-plus">+</div>
                          </div>
                        </div>
                        {/* Child */}
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={isFlightsLoading}
                    className="search-button"
                    onClick={flightSearchHandler}
                  >
                    <i className="fa fa-fw fa-search" />
                  </button>
                </div>

                {/* Passengers */}
              </div>
              {/* search Main */}
            </div>
            {/* Flight Search Tab */}
          </div>

          {/* eski yeri ankara */}
          {placeholderText()}

          {/* Flights Title */}

          <div className="selected-filter-box">
            <ul>
              {selectFilter.map((airline) => (
                <li key={airline}>
                  {airline === "AJ"
                    ? "AnadoluJet"
                    : airline === "TK"
                    ? "Türk Hava Yolları"
                    : airline === "PC"
                    ? "Pegasus"
                    : airline === "XQ"
                    ? "SunExpress"
                    : airline === "TI"
                    ? "Tailwind Airlines"
                    : ""}
                  <i onClick={() => removeAirlineFilter(airline)}>x</i>
                </li>
              ))}
            </ul>
            <ul>
              {selectTransferFilter.map((transfer) => (
                <li key={transfer}>
                  {transfer === 1
                    ? "Aktarmasız"
                    : transfer === 2
                    ? "Aktarmalı"
                    : ""}
                  <i onClick={() => removeTransferFilter(transfer)}>x</i>
                </li>
              ))}
            </ul>
            <ul>
              {promFilter === true ? (
                <li key={promFilter}>
                  Promosyonlu
                  {<i onClick={() => removePromFilter(promFilter)}>x</i>}
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>

          {flights?.FlightOptions?.length > 0 ? (
            <FlightBox
              isOneWay={isOneWay}
              selectedFlights={selectedFlights}
              setSelectedFlights={setSelectedFlights}
              flights={flights}
              departureSearchQuery={departureSearchQuery}
              arrivalSearchQuery={arrivalSearchQuery}
              airlineFilter={airlineFilter}
              transferFilter={transferFilter}
              promFilter={promFilter}
              priceRange={priceRangeFilter}
              directionRef={directionRef}
            />
          ) : (
            <div className="flights-status">
              {isFlightsLoading === null ? (
                <h2>Lütfen Uçuş Arayın.</h2>
              ) : (
                <>
                  {isFlightsLoading ? (
                    <>
                      <h1>Uçuş Aranıyor...</h1>
                      <div id="loading"></div>
                    </>
                  ) : (
                    <h2>Uçuş Bulunamadı.</h2>
                  )}
                </>
              )}
            </div>
          )}

          {/* Flight Half */}
        </div>
      </div>
      {/* Container */}
      {
        (selectedFlights?.departure || selectedFlights?.arrival) && (
          // <!-- Flight Cart -->

          <div class="flights-cart">
            <span class="label">Vergiler ve Harçlar Dahil Toplam Fiyat : </span>
            <span class="price">
              {
                //check nan if nan set zero
                (selectedFlights?.departure?.TotalFare &&
                selectedFlights?.arrival?.TotalFare
                  ? selectedFlights?.departure?.TotalFare +
                    selectedFlights?.arrival?.TotalFare
                  : selectedFlights?.departure?.TotalFare
                  ? selectedFlights?.departure?.TotalFare
                  : selectedFlights?.arrival?.TotalFare
                  ? selectedFlights?.arrival?.TotalFare
                  : 0
                ).toFixed(2)
              }
              TRY
            </span>
          </div>
        )
        // <!-- Flight Cart -->
      }
      <Toaster
        toastOptions={{
          style: {
            fontSize: "14px",
            border: "1px solid #713200",
          },
        }}
      />
    </div>
  );
}
export default App;
