import React, { useEffect, useMemo } from "react";
import FlightCard from "./FlightCard";

function FlightBox({
  flights,
  departureSearchQuery,
  arrivalSearchQuery,
  setSelectedFlights,
  selectedFlights,
  airlineFilter,
  transferFilter,
  promFilter,
  priceRange,
  directionRef,
  isOneWay,
}) {
  const segmentFilter = (flight) => {
    return flight.Segments.some((segment) => {
      if (airlineFilter.length === 0) {
        if (promFilter) {
          return segment.Cabin === "CONTRACTED";
        }
        return true;
      } else {
        if (segment.OperatingAirline === "TK") {
        }
        return (
          airlineFilter.includes(segment.OperatingAirline) &&
          (promFilter ? segment.Cabin === "CONTRACTED" : true)
        );
      }
    });
  };

  const filteredFlights = useMemo(() => {
    return flights?.FlightOptions?.filter((flight) => {
      if (
        flight.TotalFare < priceRange[0] ||
        flight.TotalFare > priceRange[1]
      ) {
        return false;
      }
      if (transferFilter) {
        if (transferFilter === 1) {
          return flight.Segments.length === 1 && segmentFilter(flight);
        }
        return flight.Segments.length > 1 && segmentFilter(flight);
      } else {
        return segmentFilter(flight);
      }
    });
  }, [flights, airlineFilter, promFilter, transferFilter, priceRange]);
  // if (ctrl) {
  return (
    <div>
      {
        <div className="flights-container">
          <div className="flights-container-item" key={1}>
            {filteredFlights?.map((flight) => (
              <React.Fragment key={flight.productId}>
                {flight.Segments[0].Way === 1 && (
                  <FlightCard
                    key={flight.productId}
                    setSelectedFlights={setSelectedFlights}
                    flight={flight}
                    selectedFlights={selectedFlights}
                  />
                )}
              </React.Fragment>
            ))}
            <>
              {filteredFlights?.filter((flight) => flight.Segments[0].Way === 1)
                .length === 0 && <h1>GİDİŞ UÇUŞU BULUNAMADI.</h1>}
            </>
          </div>
          {!isOneWay && (
            <div className="flights-container-item" key={2}>
              {filteredFlights?.map((flight) => (
                <>
                  {flight.Segments[0].Way === 2 && (
                    <FlightCard
                      setSelectedFlights={setSelectedFlights}
                      flight={flight}
                      key={flight.productId}
                      selectedFlights={selectedFlights}
                    />
                  )}
                </>
              ))}
              <>
                {filteredFlights?.filter(
                  (flight) => flight.Segments[0].Way === 2
                ).length === 0 && <h1>DÖNÜŞ UÇUŞU BULUNAMADI</h1>}
              </>
            </div>
          )}
        </div>
      }
    </div>
  );
}
// }

export default FlightBox;
